import React from 'react'
import { logoType, getSlug, replaceStr } from "../../../helper"
import IntroPlatformSection from "./layout/IntroPlatformSection"
import MainPlatformSection from "./layout/MainPlatformSection"
import RelatedPlatformSection from "./layout/RelatedPlatformSection"
import last from 'lodash/last'
import { PLATFORM_SHORTCODE } from '../../../constants'

const PlatformListeners = ({ tabs, mainElements, related, wpCptPlatform, allWpCptListener }) => {
    const platformName = [mainElements.platformNamePlural, mainElements.platformNameSingular]
    const platformSlug = `/${getSlug(wpCptPlatform.uri)}/${mainElements.slug}`

    const introHeadline = [{
        headlineValue: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            tabs.listenersH1
        ), headlineType: 'H1'
    }, {
        headlineValue: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            tabs.listenersH2
        ), headlineType: 'H2'
    }]

    const platformTabs = {
        listSlug: {
            overviewSlug: platformSlug,
            datalayersamplesSlug: `${platformSlug}/${tabs.datalayersamplesSlug}`,
            listenersSlug: `${platformSlug}/${tabs.listenersSlug}`,
            usecasesSlug: `${platformSlug}/${tabs.usecasesSlug}`,
            helpSlug: `${platformSlug}/${tabs.helpSlug}`,
        },
        hideTabs: mainElements.hideTabs,
    }

    const introBodyText = {
        headlines: [{
            headlineValue: replaceStr(
                PLATFORM_SHORTCODE,
                platformName,
                tabs.listenersH3
            ), headlineType: 'H3'
        }],
        body: [{
            bodyCopy: replaceStr(
                PLATFORM_SHORTCODE,
                platformName,
                tabs.listenersIntro
            )
        }]
    }


    const creatAccoutContent = {
        headline: mainElements.createAccountLiveStatus.createAccountHeadline ?
            mainElements.createAccountLiveStatus.createAccountHeadline :
            'Get started for free',
        subText: mainElements.createAccountLiveStatus.createAccountSubHeadline ?
            mainElements.createAccountLiveStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const creatAccoutComingSoonContent = {
        headline: mainElements.createAccountComingSoonStatus.createAccountHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountHeadline :
            'Create a Trial Account',
        subText: mainElements.createAccountComingSoonStatus.createAccountSubHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const relatedPlatform = {
        titleH3: related.relatedH3,
        description: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            related.relatedDescription
        ),
        relatedPlatforms: related.relatedPlatforms,
        showRelated: related.showRelated
    }

    const primaryLogo = logoType(
        mainElements.logoFileSvg,
        mainElements.logoFileImage,
        mainElements.useImageInsteadOfSvg,
    )

    let listenerCombinationList = []
    let listenerCommonSearchList = []



    if (Array.isArray(tabs.rules) && tabs.rules.length > 0) {
        allWpCptListener.nodes.forEach(listener => {
            let listenerHasRule = []
            const overrideTypesRulesCombination = listener.cfListener.mainElements.overrideTypesRulesCombination;
            const listenerTypes = overrideTypesRulesCombination !== null ? listener.taxListenerFilterTypes.nodes : listener.taxListenerTypes.nodes;

            const integrationSlug = `/${getSlug(listener.uri)}/${listener.cfListener.mainElements.slug}/${listener.cfListener.tabs.trackingintegrationsSlug}`

            tabs.rules.forEach(rule => {
                const combinationListenerTypes = overrideTypesRulesCombination !== null ? rule.listenerFilterTypes : rule.listenerTypes;

                combinationListenerTypes && combinationListenerTypes.forEach(listenerTypeRule => {
                    if (listenerTypes.length > 0 && listenerTypeRule.name === listenerTypes[0].name) {
                        listenerHasRule.push({
                            'name': listener.cfListener.mainElements.mainName,
                            'slug': `${integrationSlug}/${rule.formsTagmanagerIntroduction.slug}/`,
                            'icon': listener.cfListener.mainElements.iconFileSvg,
                            'image': listener.cfListener.mainElements.iconFileImage,
                            'useImage': listener.cfListener.mainElements.iconUseImageInsteadOfSvg,
                        })
                    }
                })
            })
            if (Array.isArray(listenerHasRule) && listenerHasRule.length > 0)
                listenerCombinationList.push(last(listenerHasRule))
        })
        if (Array.isArray(tabs.listenersCommonSearchedPlatforms) && tabs.listenersCommonSearchedPlatforms.length > 0) {
            tabs.listenersCommonSearchedPlatforms.forEach(listener => {
                const listenerName = listener.cfListener.mainElements.mainName
                let listenerCommonSearch = {}
                listenerCombinationList.forEach(listenerCombination => {
                    listenerCommonSearch.name = listenerName
                    if (listenerCombination.name === listenerName) {
                        listenerCommonSearch.slug = listenerCombination.slug
                    }
                })
                listenerCommonSearchList.push(listenerCommonSearch)
            })
        }
    }

    const searchListener = {
        introSearch: tabs.instructionsAboveSearch,
        commonHeadline: tabs.commonSearchedPlatformsHeadline,
        commonSearch: listenerCommonSearchList,
        listeners: listenerCombinationList,
    }

    return (
        <>
            <IntroPlatformSection
                headlines={introHeadline}
                primaryLogo={primaryLogo}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <MainPlatformSection
                tabs={platformTabs}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                introBodyText={introBodyText}
                searchListener={searchListener}
                platformName={platformName}
                creatAccoutComingSoonContent={creatAccoutComingSoonContent}
            />
            <RelatedPlatformSection
                relatedPlatform={relatedPlatform}
            />
        </>
    )
}

export default PlatformListeners
